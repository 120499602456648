<template>
    <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Promocodes
            <CCol col=11 class="d-inline-block text-right">
              <CButton
                size="sm"
                color="success"
                @click="newPromocode" 
              >
                <span>Novo Promocode</span>
              </CButton>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fieldsArray"
              :items-per-page="perPage"
              @row-clicked="rowClicked"
              :pagination="$options.paginationProps"
              index-column
              clickable-rows
            >
              
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Promocode from "@/services/PromocodeDataService";

export default {
  name: 'Promocode',
  data: () => {
    return {
      items: [],
      fieldsArray: [
        { key: 'id', label: 'Id', sorter: 1 },
        { key: 'code', label: 'Código', sorter: 1 },
        { key: 'discount', label: 'Desconto %', sorter: 1 },
        { key: 'expire_date', label: 'Valido até' },
        { key: 'max_uses', label: 'Limite de utilização' },
        { key: 'active', label: 'Ativo' }
      ],
      perPage: 25,
      largeModal: false,
      options: ['admin'],

    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    rowClicked (item, index) {
      this.$router.push({path: "promocodes/"+item.id})
    },
    newPromocode (){
      this.$router.push({path: 'promocodes/new'})
    }
  },
  
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    Promocode.getAll()
      .then(response => {
        this.items = response.data;
        this.items.map( item => item.expire_date = new Date(item.expire_date).toLocaleDateString('pt-PT'))
        this.items.map( item => item.active = item.active ? 'Sim' : 'Não')
      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>